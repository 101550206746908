'use client'

import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as React from 'react'
import { cn } from '~/utils/cn'
import { buttonIcon } from '../button-icon/button-icon.styles'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('rounded-xl border border-neutral-00  bg-neutral-00', className)}
    {...props}
  />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <AccordionPrimitive.Header className='flex'>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn('transition-all flex items-center', className)}
        {...props}
        onClick={(e) => {
          setIsOpen(!isOpen)
          props.onClick?.(e)
        }}
      >
        <div
          className={cn(
            buttonIcon({ size: 'small' }),
            'flex items-center justify-center rounded-md hover:bg-neutral-30 active:bg-neutral-40 transition-colors',
          )}
        >
          <i
            className='fa-solid fa-chevron-down text-icon-secondary transition-transform duration-200'
            style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}
          />
        </div>
        {children}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
})
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & {
    hasContentPadding?: boolean
  }
>(({ className, children, hasContentPadding = true, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden border-t border-bg-neutral-30 data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className,
    )}
    {...props}
  >
    <div className={cn(hasContentPadding && 'p-4')}>{children}</div>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { AccordionContent, Accordion as AccordionGroup, AccordionItem, AccordionTrigger }
