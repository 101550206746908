import * as React from 'react'
import { ChangeHandler } from 'react-hook-form'
import { SwitchButton } from './switch-button'

type SwitchButtonButtonProps = React.ComponentProps<typeof SwitchButton>

export enum SwitchLabelPositionEnum {
  start = 'start',
  end = 'end',
}

export type SwitchProps = SwitchButtonButtonProps & {
  label?: string
  color?: SwitchColorEnum
  tooltip?: string
  labelPosition?: SwitchLabelPositionEnum
  onCheckedChange?(checked: boolean): void // duplicate of SwitchButtonButtonProps.onCheckedChange just for the sake of autocomplete
  onChange?: ChangeHandler
}

export enum SwitchColorEnum {
  green = 'green',
  dark = 'dark',
}
