import React from 'react'
import { cn } from '~/utils/cn'
import { FileUploaderProps } from './file-uploader.types'

export const FileUploader = ({ onFileSelect, rounded = false }: FileUploaderProps) => {
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const files = e.dataTransfer.files
    if (files && files[0]) {
      onFileSelect?.(files[0])
    }
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files[0]) {
      onFileSelect?.(files[0])
    }
  }

  return (
    <div className='w-full bg-white'>
      <div
        className={cn(
          'border-neutral-20 mx-auto flex aspect-square w-[300px] cursor-pointer flex-col items-center justify-center rounded-[32px] border-2 bg-neutral-20 p-6 text-center',
          {
            'rounded-full': rounded,
          },
        )}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => document.getElementById('file-input')?.click()}
      >
        <i className='fa-regular fa-upload mb-6 text-[72px] text-[#606E7F]' />
        <div className='font-inter text-center text-sm font-normal leading-6 tracking-[-0.6px] text-neutral-secondary'>
          <p>Drag and drop file</p>
          <p>or click here to upload</p>
        </div>
        <input
          id='file-input'
          type='file'
          className='hidden'
          accept='image/*'
          data-testid='file-input'
          onChange={handleFileInput}
        />
      </div>
    </div>
  )
}
