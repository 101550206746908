import { ICON_COLORS, ICON_SIZE } from './icons.constants'
import { IconProps } from './icons.types'

export const ReferralsIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
    >
      <path
        d='M1.73385 4.47433C1.73385 3.61369 2.21602 2.79218 3.01963 2.32274C3.78306 1.89242 4.78758 1.89242 5.59119 2.32274C6.35462 2.79218 6.87697 3.61369 6.87697 4.47433C6.87697 5.37408 6.35462 6.1956 5.59119 6.66504C4.78758 7.09535 3.78306 7.09535 3.01963 6.66504C2.21602 6.1956 1.73385 5.37408 1.73385 4.47433ZM0.166806 11.1247C0.327528 9.48166 1.73385 8.22983 3.38126 8.22983H5.18938C6.27426 8.22983 7.23859 8.7775 7.80112 9.59902C7.72076 9.63814 7.60022 9.7555 7.51986 9.83374L4.9483 12.3374C4.06433 13.198 4.06433 14.5672 4.9483 15.4279L6.87697 17.3056V20.1222C6.87697 21.1785 5.99299 22 4.9483 22H3.66252C2.57764 22 1.73385 21.1785 1.73385 20.1222V15.6235C0.648973 15.2323 -0.0742777 14.2152 0.00608351 13.0416L0.166806 11.1247ZM17.1632 4.47433C17.1632 3.61369 17.6454 2.79218 18.449 2.32274C19.2124 1.89242 20.2169 1.89242 21.0205 2.32274C21.784 2.79218 22.3063 3.61369 22.3063 4.47433C22.3063 5.37408 21.784 6.1956 21.0205 6.66504C20.2169 7.09535 19.2124 7.09535 18.449 6.66504C17.6454 6.1956 17.1632 5.37408 17.1632 4.47433ZM16.4801 9.83374C16.3998 9.7555 16.2792 9.63814 16.1989 9.59902C16.7614 8.7775 17.7257 8.22983 18.8106 8.22983H20.6187C22.3063 8.22983 23.6725 9.48166 23.8332 11.1247L23.9939 13.0416C24.0743 14.2152 23.351 15.2323 22.3063 15.6235V20.1222C22.3063 21.1785 21.4223 22 20.3777 22H19.0919C18.007 22 17.1632 21.1785 17.1632 20.1222V17.3056L19.0517 15.4279C19.9357 14.5672 19.9357 13.198 19.0517 12.3374L16.4801 9.83374Z'
        fill={primaryColor}
      />
      <path
        d='M10.0172 12.5211V11.0643C10.0172 10.6546 9.76739 10.2449 9.39268 10.0628C9.01797 9.9262 8.56 10.0172 8.31019 10.2904L5.64561 13.204C5.22926 13.6592 5.22926 14.3421 5.64561 14.7518L8.31019 17.6654C8.56 17.9841 9.01797 18.0751 9.39268 17.9386C9.76739 17.7565 10.0172 17.3467 10.0172 16.8915V15.4347H14.0141V16.8915C14.0141 17.3467 14.2222 17.7565 14.5969 17.9386C14.9717 18.0751 15.4296 17.9841 15.7211 17.6654L18.3857 14.7518C18.7604 14.3421 18.7604 13.6592 18.3857 13.204L15.7211 10.2904C15.4296 10.0172 14.9717 9.9262 14.5969 10.0628C14.2222 10.2449 14.0141 10.6546 14.0141 11.0643V12.5211H10.0172Z'
        fill={secondaryColor}
      />
    </svg>
  )
}
