import { Icon } from '../icon'
import { Body, BodySize } from '../typography'

export interface CopyableTextChipProps {
  value: string
  onClick?: VoidFunction
}

export const CopyableTextChip = ({ value, onClick }: CopyableTextChipProps) => {
  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    onClick?.()
  }

  return (
    <button
      className='px-3 py-1 rounded-full bg-neutral-20 w-full flex justify-between items-center'
      onClick={() => copyToClipboard(value)}
    >
      <Body size={BodySize.small}>{value}</Body>
      <Icon icon='fa-regular fa-copy text-[#606E7F]' />
    </button>
  )
}
