'use client'

import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'
import { cn } from '~/utils/cn'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn('border-b border-neutral-30', className)}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      // Layout
      'relative',
      'inline-flex items-center justify-center p-2',
      // Base styles
      'whitespace-nowrap',
      'border-b-2 border-transparent',
      'transition-all duration-200',

      // States
      'disabled:pointer-events-none disabled:opacity-50',
      'data-[state=active]:border-brand-primary-rest',

      // Group setup for hover/active effects
      'group/tab',

      className,
    )}
    {...props}
  >
    <div className='relative'>
      <span className='relative z-10'>{children}</span>
      <span
        className={cn(
          'absolute inset-0 -z-0 m-1',

          // Hover and active states
          'group-hover/tab:bg-button-brand-primary-light-hover',
          'group-hover/tab:rounded-lg',
          'group-active/tab:bg-button-brand-primary-light-pressed',

          // Disable hover effect when tab is active
          'group-[[data-state=active]]/tab:group-hover/tab:bg-transparent',
        )}
      />
    </div>
  </TabsPrimitive.Trigger>
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={className}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs as TabsRoot, TabsList, TabsTrigger, TabsContent }
