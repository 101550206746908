import { forwardRef, HTMLAttributes } from 'react'
import DOMPurify from 'isomorphic-dompurify'

export interface SanitizedHtmlProps extends HTMLAttributes<HTMLDivElement> {
  rawHtml: string | null | undefined
}

export const SanitizedHtml = forwardRef<HTMLDivElement, SanitizedHtmlProps>(({ rawHtml, ...attrs }, ref) => {
  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawHtml ?? '') }}
      {...attrs}
    />
  )
})

SanitizedHtml.displayName = 'SanitizedHtml'
