'use client'

import { cn } from '~/utils/cn'
import { Tooltip, TooltipProvider, DefaultInfoIcon } from '../tooltip'
import { SwitchButton } from './switch-button'
import { switchStyle } from './switch.styles'
import { SwitchLabelPositionEnum, SwitchProps } from './switch.types'

export const Switch = ({
  label,
  color,
  tooltip,
  labelPosition = SwitchLabelPositionEnum.end,
  ...props
}: SwitchProps) => {
  return (
    <div
      className={cn('flex items-center gap-2', {
        'flex-row-reverse': labelPosition === SwitchLabelPositionEnum.start,
      })}
    >
      <TooltipProvider>
        <SwitchButton
          {...props}
          className={switchStyle({ color })}
        />
        {label && (
          <label
            htmlFor={props.id}
            className={cn(
              'select-none text-sm/6 font-normal text-neutral-primary',
              props.disabled && 'text-neutral-disabled',
            )}
          >
            {label}
            {tooltip && (
              <Tooltip
                content={tooltip}
                tooltipTrigger={<DefaultInfoIcon className='ml-1' />}
              />
            )}
          </label>
        )}
      </TooltipProvider>
    </div>
  )
}
