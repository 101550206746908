import { ICON_COLORS, ICON_SIZE } from './icons.constants'
import { IconProps } from './icons.types'

export const ReportsIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
  notification = false,
}: IconProps & { notification?: boolean }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group'>
        <path
          id='Subtract'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.2583 2.99625L14.0009 2.99625H9.99913H7.49814C5.01183 2.99625 2.99627 5.01181 2.99627 7.49813V9.99911L2.99622 9.99917V16.5019C2.99622 18.5689 4.38926 20.3105 6.28773 20.8392C6.67286 20.9464 7.07878 21.0037 7.49809 21.0037H7.49814H16.5018H16.5019C16.9292 21.0037 17.3426 20.9442 17.7343 20.833C19.6215 20.297 21.0038 18.5609 21.0038 16.5019V9.99917L21.0037 9.99911V8.74171C20.5344 8.90764 20.0293 8.99792 19.5031 8.99792C17.0173 8.99792 15.0021 6.98274 15.0021 4.49688C15.0021 3.9707 15.0924 3.46561 15.2583 2.99625Z'
          fill={secondaryColor}
        />
        <path
          id='Path'
          d='M14.0009 7.99833H10.9996'
          stroke={primaryColor}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Path_2'
          d='M10.9996 12H17.0021'
          stroke={primaryColor}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Path_3'
          d='M10.9996 16.0017H17.0021'
          stroke={primaryColor}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Path_4'
          d='M6.99789 15.7516C6.93132 15.7507 6.86723 15.7768 6.82015 15.8239C6.77307 15.871 6.74698 15.9351 6.74779 16.0017C6.74779 16.1398 6.85977 16.2518 6.99789 16.2518C7.13603 16.2518 7.248 16.1398 7.248 16.0017C7.248 15.8635 7.13603 15.7516 6.99789 15.7516'
          stroke={primaryColor}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Path_5'
          d='M6.99789 7.74823C6.93132 7.74742 6.86723 7.7735 6.82015 7.82059C6.77307 7.86767 6.74698 7.93176 6.74779 7.99832C6.74779 8.13646 6.85977 8.24844 6.99789 8.24844C7.13603 8.24844 7.248 8.13646 7.248 7.99832C7.248 7.8602 7.13603 7.74823 6.99789 7.74823'
          stroke={primaryColor}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Path_6'
          d='M6.99789 11.7499C6.93132 11.7491 6.86723 11.7752 6.82015 11.8223C6.77307 11.8693 6.74698 11.9334 6.74779 12C6.74779 12.1381 6.85977 12.2501 6.99789 12.2501C7.13603 12.2501 7.248 12.1381 7.248 12C7.248 11.8619 7.13603 11.7499 6.99789 11.7499'
          stroke={primaryColor}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        {notification && (
          <circle
            xmlns='http://www.w3.org/2000/svg'
            id='Oval'
            cx='19'
            cy='5'
            r='2.50104'
            fill={primaryColor}
          />
        )}
      </g>
    </svg>
  )
}
