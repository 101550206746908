import { Routes } from '~/src/app/_constants/routes'
import { AnalyticsIcon, CalendarIcon, DashboardIcon, TalentPoolIcon } from './icons'
import { NavItems } from './sidebar.types'

export const NAV_ITEMS: NavItems = {
  DASHBOARD: {
    label: 'Dashboard',
    href: '/company/home/active',
    activePath: '/company/home',
    icon: DashboardIcon,
  },
  /* PIPELINE: {
    label: "Pipeline",
    href: "/company/pipelines/1",
    activePath: "pipeline",
    icon: PipelineIcon,
  }, */
  CALENDAR: {
    label: 'Calendar',
    href: '/company/calendar',
    activePath: 'calendar',
    icon: CalendarIcon,
  },
  TALENT_POOL: {
    label: 'Talent Pool',
    href: Routes.company.talent_pool,
    activePath: 'talent-pool',
    icon: TalentPoolIcon,
  },
  ANALYTICS: {
    label: 'Analytics',
    href: '/company/home/active',
    icon: AnalyticsIcon,
  },
}
