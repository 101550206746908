'use client'

import {
  ButtonIcon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/scalis-components/core'
import { SORT_BY_OPTIONS } from '../../../../job-requisitions.constants'

interface SortByMenuProps {
  expand: () => void
}

export const SortByMenu = ({ expand }: SortByMenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ButtonIcon
          icon='fa-regular fa-filter'
          onPointerDown={expand}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='bg-neutral-00 p-2'>
        {SORT_BY_OPTIONS.map((option) => (
          <DropdownMenuItem
            key={option.value}
            className='cursor-pointer justify-between gap-2 rounded-lg p-2 hover:bg-neutral-20'
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
