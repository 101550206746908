import { tv } from 'tailwind-variants'

export const tabsRootStyles = tv({
  base: 'flex w-full gap-4',
  variants: {
    isVertical: {
      true: '',
      false: 'flex flex-col',
    },
  },
})

export const tabsListStyles = tv({
  base: 'mb-0 flex items-center justify-between gap-2',
  variants: {
    variant: {
      primary: '',
      secondary: '',
      tertiary: 'border-none',
    },
    isVertical: {
      true: 'border-b-0 border-r',
    },
  },
})

export const tabsTriggerStyles = tv({
  base: 'group cursor-pointer',
  variants: {
    variant: {
      primary: '',
      secondary: '',
      tertiary: 'p-0 data-[state=active]:border-none',
    },
    isVertical: {
      true: 'justify-start data-[state=active]:border-b-0 data-[state=active]:bg-brand-primary-light-10',
    },
  },
})

export const innerTabsListStyles = tv({
  base: 'whitespace-nowrap',
  variants: {
    variant: {
      primary: '',
      secondary: 'flex justify-evenly w-full',
      tertiary: 'mb-[-2px] border-none',
    },
    isVertical: {
      true: 'flex h-full flex-col overflow-y-auto',
      false: 'min-h-fit overflow-x-auto overflow-y-hidden',
    },
  },
})

export const tabsStyles = tv({
  base: 'h-full rounded-b-none md:px-3 py-0 text-neutral-secondary group-data-[state=active]:text-neutral-primary',
  variants: {
    variant: {
      primary: '',
      secondary: 'text-neutral-tertiary group-data-[state=active]:text-scalis-logo-color',
      tertiary:
        'flex select-none items-center rounded-t-xl bg-transparent py-2 px-4 group-data-[state=active]:border group-data-[state=active]:border-b-0 group-data-[state=active]:bg-neutral-00',
    },
  },
})

export const tabsLabelStyles = tv({
  base: 'text-sm/6 font-medium tracking-tighter text-neutral-secondary',
  variants: {
    isActive: {
      true: 'text-brand-primary-10',
    },
  },
})

export const hrStyles = tv({
  base: 'h-3 border-r border-r-neutral-10 group-data-[state=active]:border-r-transparent',
  variants: {
    borderless: {
      true: 'border-r-transparent',
    },
  },
})
