import { ReactNode } from 'react'
import { memoize } from 'lodash'
import { notFound } from 'next/navigation'
import { RoleEnum } from '~/bff/graphql/generated/graphql'
import { useUserContext } from '~/src/providers/user-provider'

/**
 * Determines if the user has any of the required roles.
 *
 * @param allowedRoles - A set of roles required to access a feature.
 * @param userRoles - A set of roles the user currently has.
 * @returns {boolean} - True if the user has any of the required roles, otherwise false.
 */
export const hasRole = memoize((allowedRoles: RoleEnum[] = [], userRoles: RoleEnum[] = []) =>
  allowedRoles.some((role) => userRoles.includes(role)),
)

/**
 * Determines if the user has any of the required roles.
 *
 * @param requiredRoles - A set of roles required to access a feature.
 * @param redirect - Whether to redirect to a 404 page if the user does not have the required roles.
 * @returns {boolean} - True if the user has any of the required roles, otherwise false.
 */
export const useHasPermissions = (requiredRoles: RoleEnum[], redirect = false) => {
  const { currentWorkspace } = useUserContext()
  const roles = currentWorkspace?.currentCompany?.roles as RoleEnum[]
  const allowed = hasRole(requiredRoles, roles)
  if (redirect && !allowed) {
    notFound()
  }
  return allowed
}

interface RequireRoleProps {
  children: ReactNode
  requiredRoles: RoleEnum[]
}
export const RequireRole = ({ children, requiredRoles }: RequireRoleProps) => {
  const allowed = useHasPermissions(requiredRoles)

  if (allowed) {
    return children
  }
  return <></>
}
