'use client'

import React, { useState, useEffect, forwardRef } from 'react'
import { cn } from '~/utils/cn'
import { Slider } from '../slider'
import { defaultAdjustments } from './logo-editor.constants'
import { ImageAdjustments, LogoEditorProps } from './logo-editor.types'

export const LogoEditor = forwardRef<HTMLDivElement, LogoEditorProps>(({ file, rounded = false }, ref) => {
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [adjustments, setAdjustments] = useState(defaultAdjustments)
  const [previewUrl, setPreviewUrl] = useState<string>('')

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsDragging(true)

    if (!ref || typeof ref === 'function' || !ref.current) return

    const rect = ref?.current?.getBoundingClientRect()
    if (rect) {
      setDragStart({
        x: e.clientX - (adjustments.position.x * rect.width) / 100,
        y: e.clientY - (adjustments.position.y * rect.height) / 100,
      })
    }
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !ref || typeof ref === 'function' || !ref.current) return

    const rect = ref.current.getBoundingClientRect()

    // Calculate new position in pixels
    const newX = e.clientX - dragStart.x
    const newY = e.clientY - dragStart.y

    // Convert to percentages
    const percentX = (newX / rect.width) * 100
    const percentY = (newY / rect.height) * 100

    // Limit the movement range (-50% to 50%)
    const boundedX = Math.max(Math.min(percentX, 50), -50)
    const boundedY = Math.max(Math.min(percentY, 50), -50)

    setAdjustments({
      ...adjustments,
      position: {
        x: boundedX,
        y: boundedY,
      },
    })
  }

  useEffect(() => {
    const handleMouseUp = () => setIsDragging(false)

    const handleMouseLeave = () => {
      if (isDragging) {
        setIsDragging(false)
      }
    }

    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  const handleSliderChange = (name: keyof ImageAdjustments) => (value: number[]) => {
    setAdjustments({
      ...adjustments,
      [name]: value[0],
    })
  }

  useEffect(() => {
    const url = URL.createObjectURL(file)
    setPreviewUrl(url)
    return () => URL.revokeObjectURL(url)
  }, [file])

  return (
    <div className='w-full bg-white p-8'>
      <div className='flex xxs:max-md:flex-col gap-8'>
        <div
          className={cn('border-2 border-neutral-20 overflow-hidden bg-[#F8F9FC]  self-center justify-self-center', {
            'rounded-2xl': !rounded,
            'rounded-full': rounded,
          })}
        >
          <div
            ref={ref}
            className='h-[300px] w-[300px]'
          >
            <div
              className='relative h-full w-full cursor-move'
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              style={{ touchAction: 'none' }}
            >
              {previewUrl && (
                <img
                  src={previewUrl}
                  alt='Logo preview'
                  className='h-full w-full rounded-2xl object-contain'
                  draggable={false}
                  style={{
                    transform: `
                      translate(${adjustments.position.x}%, ${adjustments.position.y}%)
                      scale(${1 + adjustments.zoom / 100})
                      rotate(${adjustments.straighten}deg)
                    `,
                    filter: `brightness(${100 + adjustments.brightness}%) contrast(${100 + adjustments.contrast}%)`,
                    transition: isDragging ? 'none' : 'transform 0.1s ease-out, filter 0.1s ease-out',
                    userSelect: 'none',
                    pointerEvents: 'none',
                  }}
                />
              )}
              <div
                className='pointer-events-none absolute inset-0'
                style={{
                  backgroundImage: `
                    linear-gradient(to right, rgba(128, 128, 128, 0.1) 1px, transparent 1px),
                    linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 1px, transparent 1px)
                  `,
                  backgroundSize: '20% 20%',
                }}
              />
            </div>
          </div>
        </div>

        <div className='flex flex-1 flex-col justify-end gap-8'>
          {[
            { name: 'zoom', label: 'Zoom' },
            { name: 'straighten', label: 'Straighten' },
            { name: 'brightness', label: 'Brightness' },
            { name: 'contrast', label: 'Contrast' },
          ].map((slider) => {
            const value = adjustments[slider.name as keyof Omit<ImageAdjustments, 'position'>]
            return (
              <div
                key={slider.name}
                className='flex flex-col gap-2'
              >
                <div className='flex justify-between'>
                  <span className='text-base font-normal text-neutral-primary'>{slider.label}</span>
                  <span className='text-base font-normal text-neutral-primary'>{value}</span>
                </div>
                <Slider
                  defaultValue={[0]}
                  min={-100}
                  max={100}
                  step={1}
                  value={[value]}
                  onValueChange={handleSliderChange(slider.name as keyof ImageAdjustments)}
                />
              </div>
            )
          })}
        </div>
      </div>

      <div className='mt-4 flex justify-self-center'>
        <div className='w-[300px]'>
          <p className='font-inter text-center text-sm font-normal leading-6 tracking-[-0.6px] text-[#606E7F]'>
            Drag to reposition the picture.
          </p>
        </div>
      </div>
    </div>
  )
})

LogoEditor.displayName = 'LogoEditor'
