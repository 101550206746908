'use client'

import { usePathname } from 'next/navigation'
import { RoleEnum } from '~/bff/graphql/generated/graphql'
import { RequireRole } from '~/hooks/use-has-permissions'
import {
  AnalyticsIcon,
  CalendarIcon,
  DashboardIcon,
  Divider,
  ReferralsIcon,
  ReportsIcon,
  TalentPoolIcon,
} from '~/scalis-components/core'
import { Routes } from '~/src/app/_constants/routes'
import { Aside } from '../aside'
import { JobRequisitions } from './components'

export const DefaultSidebar = () => {
  const pathname = usePathname()
  const isActive = (path: string) => pathname?.includes(path)
  const isProd = process.env.NEXT_PUBLIC_DATADOG_ENV === 'prod'

  return (
    <Aside.Container
      collapsible
      collapsed
      persistCollapsed
    >
      {({ showContent, expand }) => {
        return (
          <>
            <Aside.List>
              <Aside.Item
                label='Dashboard'
                href={Routes.company.active}
                icon={DashboardIcon}
                active={isActive(Routes.company.active)}
                showContent={showContent}
              />
              {
                <Aside.Item
                  label='Calendar'
                  href={Routes.company.calendar}
                  icon={CalendarIcon}
                  active={isActive(Routes.company.calendar)}
                  showContent={showContent}
                />
              }
              <Aside.Item
                label='Talent Pool'
                href={Routes.company.talent_pool}
                icon={TalentPoolIcon}
                active={isActive(Routes.company.talent_pool)}
                showContent={showContent}
              />
              {!isProd && (
                <RequireRole requiredRoles={[RoleEnum.Admin, RoleEnum.SuperAdmin]}>
                  <Aside.Item
                    label='Analytics'
                    href={Routes.company.analytics}
                    active={isActive(Routes.company.analytics)}
                    icon={AnalyticsIcon}
                    showContent={showContent}
                  />
                </RequireRole>
              )}
              {!isProd && (
                <Aside.Item
                  label='Reports'
                  href={Routes.company.reports}
                  active={isActive(Routes.company.reports)}
                  icon={() => <ReportsIcon notification={true} />}
                  showContent={showContent}
                />
              )}
              {!isProd && (
                <Aside.Item
                  label='Referrals'
                  href={Routes.company.referrals}
                  active={isActive(Routes.company.referrals)}
                  icon={ReferralsIcon}
                  showContent={showContent}
                />
              )}
            </Aside.List>
            <Divider />
            <div className='overflow-y-auto'>
              <JobRequisitions
                showContent={showContent}
                expand={expand}
              />
            </div>
          </>
        )
      }}
    </Aside.Container>
  )
}
