import { ImageAdjustments } from './logo-editor.types'

export const defaultAdjustments: ImageAdjustments = {
  zoom: 0,
  straighten: 0,
  brightness: 0,
  contrast: 0,
  position: {
    x: 0,
    y: 0,
  },
}
